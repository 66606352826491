<template>
  <div class="container">
    <div class="mian-content">
      <p class=MsoNormal>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:14.0pt;'>招聘职位：</span>
        </b>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:14.0pt'>
            <o:p></o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;'>一、行政前台
            <span lang=EN-US>/</span>专员：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>岗位职责：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、接听电话，接收传真，按要求转接电话或记录信息，确保及时准确。
          <span lang=EN-US>
            <br> 2
          </span>、对来访客人做好接待、登记、引导客人参观、展厅介绍工作。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>3</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、负责公司快递、信件、包裹的收发工作
          <span lang=EN-US>
            <br> 4
          </span>、负责办公用品的盘点工作，做好登记存档。并对办公用品的领用、发放、出入库做好登记。不定时检查用品库存，及时做好后勤保障工作。
          <span lang=EN-US>
            <br> 5
          </span>、负责每月统计公司员工的考勤情况，考勤资料存档。
          <span lang=EN-US>
            <br> 6
          </span>、做好会前准备、会议记录和会后内容整理工作
          <span lang=EN-US>
            <br> 7
          </span>、做好材料收集、档案管理等工作。
          <span lang=EN-US>
            <br> 8
          </span>、协助上级完成公司行政事务工作及部门内部日常事务工作。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>岗位要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、大专及本科以上学历，
          <span lang=EN-US>1-2</span>年相关工作经验。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>2</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、熟练操作办公自动化设备，包括：计算机、打印机、传真机、复印机、投影仪、扫描仪等。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>3</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、诚实敬业、工作认真细心、责任心强、开朗热情&#8194;，语言表达能力优秀。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>4</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、身高
          <span lang=EN-US>1.65</span>及以上，形象好、气质佳。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;font-family:
宋体;mso-bidi-font-family:宋体'>招聘人数：</span>
        </b>
        <span lang=EN-US style='font-size:
12.0pt;mso-bidi-mso-bidi-font-weight:bold'>1-2</span>
        <span style='font-size:12.0pt;mso-bidi-mso-bidi-font-weight:
bold'>名
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;
'>
            <o:p>&nbsp;</o:p>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>二、软件工程师
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>岗位职责：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、负责软件平台开发；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0'>
        <span lang=EN-US style='font-size:12.0pt;
'>2</span>
        <span style='font-size:12.0pt;
'>、负责软件的维护和升级。</span>
        <span lang=EN-US style='font-size:12.0pt;
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:
华文宋体'>
          <o:p></o:p>
        </span>
      </p>

      <p style='mso-pagination:widow-orphan'>
        <b>
          <span style='
'>岗位要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l0 level1 lfo1;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>1、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>本科及以上学历，计算机科学与技术、软件工程等相关专业；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l0 level1 lfo1;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>2、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>精通
          <span lang=EN-US>C/C++</span>或
          <span lang=EN-US>Java</span>编程；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l0 level1 lfo1;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>3、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>对数据结构、算法等有一定研究；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l0 level1 lfo1;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>4、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>有较强的学习能力，能快速掌握新技术；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l0 level1 lfo1;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>5、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>具备良好的沟通和团队合作能力，有良好的职业素养；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l0 level1 lfo1;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>6、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>性格稳重，吃苦耐劳，肯钻研，善于学习新技术；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l0 level1 lfo1;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>7、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>具有良好的执行能力和持续改进能力。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0;
tab-stops:3.0pt'>
        <b>
          <span style='font-size:12.0pt;
mso-fareast-theme-font:minor-fareast;
'>招聘人数：不限</span>
        </b>
        <span lang=EN-US style='font-size:
12.0pt;
mso-fareast-theme-font:minor-fareast;'>
          <o:p></o:p>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;
'>
            <o:p>&nbsp;</o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>三、嵌入式开发工程师</span>
        </b>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>
          <o:p></o:p>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:6.0pt;mso-char-indent-count:.5;
tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:
12.0pt;
mso-fareast-theme-font:minor-fareast;'>岗位描述：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:42.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l2 level1 lfo2;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>1、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>从事硬件嵌入式软件及驱动研发；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:42.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l2 level1 lfo2;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>2、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>跟踪相应国际、国内前沿技术。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:6.0pt;mso-char-indent-count:.5;
tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:
12.0pt;
mso-fareast-theme-font:minor-fareast;'>任职要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>1</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>、大学本科及以上学历；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>2</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>、物理、电子电路、自动化等专业，本科及以上学历；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>3</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>、熟悉单片机系统，数字、模拟电路基础良好，熟悉
          <span lang=EN-US>C</span>编程，精通单片机；为核心的外围硬件设计，具备一定的硬件调试能力；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>4</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>、有一定英文电子类技术文章阅读能力；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>5</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>、有较强的学习能力，能快速掌握新技术。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b>
          <span style='font-size:12.0pt;
mso-fareast-theme-font:
minor-fareast;'>招聘人数：不限</span>
        </b>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>
          <o:p></o:p>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;
'>
            <o:p>&nbsp;</o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>四、硬件安全工程师
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>岗位职责：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>1</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>、研究手机硬件平台的架构，调试接口，通讯接口及协议；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>2</span>
        <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>、</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>研究智能硬件设备的安全、攻防；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>3</span>
        <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>、</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>研究支付终端类设备安全；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>4</span>
        <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>、</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>研究智能设备硬件安全标准和解决方案。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>5</span>
        <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>、</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>对智能硬件设备进行硬件相关的渗透性测试。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>岗位要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>1.</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>了解
          <span lang=EN-US>LINUX</span>操作系统的工作原理，熟悉各类常用开发语言以及编译环境，精通
          <span lang=EN-US>C/C++/GUN C
          </span>编程；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>2.</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>在以下领域中有过攻防、渗透、漏洞挖掘的经验：移动
          <span lang=EN-US>OS</span>、嵌入式系统（系统逆向、分析）；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>3.</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>有逆向工程及破解经验优先；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>4.</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>熟悉物联网、智能设备产业现状和安全隐患者优先。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>5.</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>有较强的团队协作精神，责任心强，做事积极主动，有良好的沟通能力，能够独立开展工作。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:21.0pt;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>6.</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>本科及以上学历；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b>
          <span style='font-size:12.0pt;
mso-fareast-theme-font:
minor-fareast;'>招聘人数：不限</span>
        </b>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>
          <o:p></o:p>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;
mso-ascii-theme-font:minor-fareast;mso-fareast-theme-font:
minor-fareast;mso-hansi-theme-font:minor-fareast;'>
            <o:p>&nbsp;</o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>五、
            <span lang=EN-US>Linux</span>安全研发工程师
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>岗位要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:
12.0pt;mso-ascii-theme-font:minor-fareast;
mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:
minor-fareast;'>
          <span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp; </span>1.</span>
        <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>本科及以上学历，计算机科学及软件工程相关专业
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:
12.0pt;mso-ascii-theme-font:minor-fareast;
mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:
minor-fareast;'>
          <span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp; </span>2.</span>
        <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>熟悉
          <span lang=EN-US>Linux/UNIX</span>系统，熟悉
          <span lang=EN-US>arm-
            <span class=SpellE>linux</span>
          </span>（安卓）系统
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-ascii-theme-font:minor-fareast;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:minor-fareast;
'>3.</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-ascii-theme-font:minor-fareast;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:minor-fareast;
'>有
          <span lang=EN-US>arm-
            <span class=SpellE>linux</span>
          </span>系统成功攻击案例者优先
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-ascii-theme-font:minor-fareast;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:minor-fareast;
'>4.</span>
        <span class=GramE>
          <span style='font-size:
12.0pt;mso-ascii-theme-font:minor-fareast;
mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:
minor-fareast;'>熟悉微</span>
        </span>
        <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>内核
          <span lang=EN-US>L4</span>原理及攻击技术者优先
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-ascii-theme-font:minor-fareast;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:minor-fareast;
'>5.</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-ascii-theme-font:minor-fareast;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;mso-hansi-theme-font:minor-fareast;
'>有较强的学习能力，能快速掌握新技术
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>岗位职责：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='text-indent:24.1pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>
            <span style='mso-spacerun:yes'>&nbsp;</span>
          </span>
        </b>
        <span lang=EN-US style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>1</span>
        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>、协助支持安卓或者
          <span class=SpellE>
            <span lang=EN-US>linux</span>
          </span>上工程实现
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:30.0pt;mso-char-indent-count:2.5;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>2</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、承担软件漏洞分析相关的研发学习任务
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:30.0pt;mso-char-indent-count:2.5;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>3</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、承担必要的软件开发任务
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:30.0pt;mso-char-indent-count:2.5;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>4</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、其它工作事项
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b>
          <span style='font-size:12.0pt;
mso-fareast-theme-font:
minor-fareast;'>招聘人数：不限</span>
        </b>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>
          <o:p></o:p>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;
mso-ascii-theme-font:minor-fareast;mso-fareast-theme-font:
minor-fareast;mso-hansi-theme-font:minor-fareast;'>
            <o:p>&nbsp;</o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>六、算法工程师
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>岗位职责：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、从事侧信道、故障注入等攻击技术研发；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>2</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、跟踪相应的国际和国内前沿技术；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>岗位要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、大学本科及以上学历；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>2</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、熟悉密码算法；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>5</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、熟练使用
          <span lang=EN-US>C</span>语言进行编程开发；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>6</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、有侧信道、故障注入、硬件渗透者经验者优先；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='text-indent:24.0pt;mso-char-indent-count:2.0;
tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>7</span>
        <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;mso-fareast-theme-font:minor-fareast;
'>、有一定英文电子类技术文章阅读能力者优先。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b>
          <span style='font-size:12.0pt;
mso-fareast-theme-font:
minor-fareast;'>招聘人数：不限</span>
        </b>
        <span lang=EN-US style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>
          <o:p></o:p>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;
mso-ascii-theme-font:minor-fareast;mso-fareast-theme-font:
minor-fareast;mso-hansi-theme-font:minor-fareast;'>
            <o:p>&nbsp;</o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <span style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>注：
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:0cm;text-indent:0cm;mso-list:l3 level1 lfo3;
tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>
          <span style='mso-list:Ignore'>1、</span>
        </span>

        <span style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>工作地点在江西省鹰潭市高新区
          <span class=GramE>炬</span>能大厦；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='margin-left:0cm;text-indent:0cm;mso-list:l3 level1 lfo3;
tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>
          <span style='mso-list:Ignore'>2、</span>
        </span>

        <span style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>工资面议。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:
12.0pt;mso-bidi-mso-bidi-font-weight:bold'>
          <o:p>&nbsp;</o:p>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:
12.0pt;mso-bidi-mso-bidi-font-weight:bold'>
          <o:p>&nbsp;</o:p>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:宋体'>联系人：
            <span lang=EN-US>
              <span style='mso-spacerun:yes'>&nbsp; </span>
            </span>于洋
            <span lang=EN-US>
              <span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>李海滨
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:宋体'>联系电话：
            <span lang=EN-US>13699250522
              <span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>18600002720
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal>
        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>
          <o:p>&nbsp;</o:p>
        </span>
      </p>

      <p class=MsoNormal>
        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>
          <o:p>&nbsp;</o:p>
        </span>
      </p>

      <p class=MsoNormal>
        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>
          <o:p>&nbsp;</o:p>
        </span>
      </p>

      <p class=MsoNormal>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:14.0pt;
mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>急聘：（江西智安检测中心有限公司）</span>
        </b>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:14.0pt'>
            <o:p></o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>一、行政前台
            <span lang=EN-US>/</span>专员：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-ascii-theme-font:
minor-fareast;mso-fareast-theme-font:minor-fareast;
mso-hansi-theme-font:minor-fareast;'>岗位职责：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、负责园区事务的联系、沟通和协调工作。
          <span lang=EN-US>
            <br> 2
          </span>、负责公司引导客人参观、展厅介绍工作。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>3</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、负责公司快递、信件、包裹的收发工作。
          <span lang=EN-US>
            <br> 4
          </span>、负责办公用品的盘点和登记工作。
          <span lang=EN-US>
            <br> 5
          </span>、负责员工考勤统计工作。
          <span lang=EN-US>
            <br> 6
          </span>、负责公司档案管理等工作。
          <span lang=EN-US>
            <br> 7
          </span>、完成其他公司行政事务工作。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal style='tab-stops:3.0pt'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>岗位要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、大专及以上学历，
          <span lang=EN-US>1-2</span>年相关工作经验。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>2</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、熟练操作办公自动化设备，包括：计算机、打印机、传真机、复印机、投影仪、扫描仪等。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>3</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、熟练使用办公软件，包括
          <span lang=EN-US>word</span>，
          <span lang=EN-US>excel</span>等。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>4</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、诚实敬业、工作认真细心、责任心强、开朗热情&#8194;，语言表达能力优秀。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;font-family:
宋体;mso-bidi-font-family:宋体'>招聘人数：</span>
        </b>
        <span lang=EN-US style='font-size:
12.0pt;mso-bidi-mso-bidi-font-weight:bold'>1-2</span>
        <span style='font-size:12.0pt;mso-bidi-mso-bidi-font-weight:
bold'>名
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;font-family:
宋体;mso-bidi-font-family:宋体'>预期工资：</span>
        </b>
        <span lang=EN-US style='font-size:
12.0pt;mso-bidi-mso-bidi-font-weight:bold'>2500-3500</span>
        <span style='font-size:12.0pt;mso-bidi-mso-bidi-font-weight:
bold'>元
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span lang=EN-US style='font-size:12.0pt;
'>
            <o:p>&nbsp;</o:p>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>二、软硬件研发、测试工程师
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoNormal>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>岗位职责：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0;tab-stops:3.0pt'>
        <span lang=EN-US style='font-size:12.0pt;mso-bidi-font-size:
11.0pt;'>1</span>
        <span style='font-size:12.0pt;mso-bidi-font-family:
华文宋体'>、负责软硬件平台开发；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:21.0pt;text-indent:0cm;mso-char-indent-count:
0'>
        <span lang=EN-US style='font-size:12.0pt;
'>2</span>
        <span style='font-size:12.0pt;
'>、负责软硬件的维护和升级。</span>
        <span lang=EN-US style='font-size:12.0pt;
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:
华文宋体'>
          <o:p></o:p>
        </span>
      </p>

      <p style='mso-pagination:widow-orphan'>
        <b>
          <span style='
'>岗位要求：
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </span>
        </b>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l1 level1 lfo4;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>1、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>本科及以上学历，计算机、自动化控制、电子工程、通信等相关专业；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l1 level1 lfo4;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>2、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>精通
          <span lang=EN-US>C/C++</span>或
          <span lang=EN-US>Java</span>编程；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l1 level1 lfo4;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>3、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>有较强的学习能力，能快速掌握新技术；
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l1 level1 lfo4;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>4、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>具备良好的沟通和团队合作能力，有良好的职业素养；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l1 level1 lfo4;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>5、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>性格稳重，吃苦耐劳，肯钻研，善于学习新技术；
          <span lang=EN-US>&nbsp;
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='margin-left:39.0pt;text-indent:-18.0pt;
mso-char-indent-count:0;mso-list:l1 level1 lfo4;tab-stops:3.0pt'>

        <span lang=EN-US style='font-size:12.0pt;
mso-bidi-font-family:宋体'>
          <span style='mso-list:Ignore'>6、</span>
        </span>

        <span style='font-size:12.0pt;mso-fareast-font-family:
宋体;mso-fareast-theme-font:minor-fareast;'>具有良好的执行能力和持续改进能力。
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0;
tab-stops:3.0pt'>
        <b>
          <span style='font-size:12.0pt;
mso-fareast-theme-font:minor-fareast;
'>招聘人数：</span>
        </b>
        <span style='font-size:12.0pt;
mso-fareast-theme-font:
minor-fareast;'>不限
          <b>
            <span lang=EN-US>
              <o:p></o:p>
            </span>
          </b>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0'>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;font-family:
宋体;mso-bidi-font-family:宋体'>预期工资：</span>
        </b>
        <span style='font-size:12.0pt;
mso-bidi-mso-bidi-font-weight:bold'>面议
          <span lang=EN-US>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoListParagraph style='text-indent:0cm;mso-char-indent-count:0;
tab-stops:3.0pt'>
        <b>
          <span style='font-size:12.0pt;
mso-fareast-theme-font:minor-fareast;
'>联系人： </span>
        </b>
        <b style='mso-bidi-font-weight:normal'>
          <span style='font-size:12.0pt;mso-bidi-font-family:宋体'>于洋
            <span lang=EN-US>
              <span style='mso-spacerun:yes'>&nbsp; </span>13699250522</span>
          </span>
        </b>
        <b>
          <span lang=EN-US style='font-size:12.0pt;
mso-fareast-theme-font:minor-fareast;mso-bidi-font-family:
华文宋体'>
            <o:p></o:p>
          </span>
        </b>
      </p>

      <p class=MsoNormal>
        <span style='font-size:12.0pt;mso-bidi-font-family:
宋体;mso-bidi-font-weight:bold'>简历投递邮箱：
          <span lang=EN-US>
            <a href="mailto:hr@dplslab.com">hr@dplslab.com</a>
            <o:p></o:p>
          </span>
        </span>
      </p>

      <p class=MsoNormal>
        <span style='font-size:12.0pt;mso-bidi-font-family:
宋体;mso-bidi-font-weight:bold'>公司地址：江西省鹰潭市月湖区炬能路
          <span lang=EN-US>3</span>号
          <span class=GramE>炬</span>能大厦
          <span lang=EN-US>A</span>座
          <span lang=EN-US>F19
            <o:p></o:p>
          </span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  min-height: 840px;
  width: 1170px;
  background-color: #fff;
  margin: 20px auto;
  padding: 40px 0px;
  .mian-content {
    padding: 0px 80px;
    width: 100%;
    p {
      text-align: left;
    }
  }
}
</style>
